import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify"

export default function UnsubscribeForm() {
  const [cancelSubscriptionButton, setCancelSubscriptionButton] = useState(false);
  const [userId, setUserId] = useState(null);
  const [subscriptionId, setSubscriptionId] = useState(null);
  const [subscriptionEndDate, setSubscriptionEndDate] = useState(null);

  // Fetch user info on component mount
  useEffect(() => {
    async function getUserInfo() {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/dashboard/userinfo`,
          {
            method: "GET",
            headers: { token: localStorage.token },
          }
        );

        const parseRes = await response.json();
        setUserId(parseRes[0].user_id);
        setSubscriptionId(parseRes[0].subscription_id);
        setSubscriptionEndDate(parseRes[0].subscription_end_date);
      } catch (err) {
        console.error("Error fetching user info:", err.message);
      }
    }
    getUserInfo();
  }, []);

  const navigate = useNavigate();

  const cancelSubscription = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/auth/cancel-subscription`,
        {
          method: "DELETE",
          headers: { 'Content-Type': 'application/json', token: localStorage.token },
          body: JSON.stringify({ userId, subscriptionId })
        }
      )

      const parseRes = await res.json();

      toast.success(parseRes.message);
      setSubscriptionId(null);
    } catch (error) {
      console.error("Unable to cancel subscription at this time", error.message);
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setCancelSubscriptionButton(true);
    }, 5000)
  }, [cancelSubscriptionButton])

  const handleCancellationClick = async () => {
    await cancelSubscription();
    navigate("/settings");
  }

  const handleBackToDashboard = () => {
    navigate("/dashboard");
  }

  return (
    <div className="flex flex-col w-dvw h-[95dvh] justify-center items-center">
      <iframe className="w-[100%] h-[100%]" src="https://docs.google.com/forms/d/e/1FAIpQLSeWzzsoDwa_cLJp1XrixjhqfFqQ0hZzGjDqqeCKNRc_aA8U2w/viewform?embedded=true">Loading…</iframe>
      <div className="w-[90%] max-w-[640px] mx-auto gap-3 flex justify-center">
        {cancelSubscriptionButton && !subscriptionEndDate ? (
          <>
            <button className="hover:text-accent1 text-accent2 font-semibold w-[50%] p-2 ml-[-.45rem]" onClick={handleBackToDashboard}>Back to Dashboard</button>
            <button className="bg-ktx-red hover:bg-red-600 rounded-md w-[50%] p-2 text-white mr-[.45rem]" onClick={handleCancellationClick}>Cancel Subscription</button>
          </>
        ) : (
          <button className="hover:text-accent1 text-accent2 font-semibold mr-[.45rem] ml-[-.45]" onClick={handleBackToDashboard}>Back to Dashboard</button>

        )}
      </div>
    </div>
  )
}