import React, { useState } from "react";

// This modal is only for when the JSON response has an "alternative" or "similar" field.
// Code selection will replace the "unspecified" code with the chosen code and update DB (optional).

export default function AlternativeCodeModal({
  code,
  setShowAlternativeCodes,
  isUnspecifiedAnemia,
  selectedCodes,
  setSelectedCodes,
  setCodesChange,
  patientID,
}) {
  const [selectedOption, setSelectedOption] = useState(null);

  // Decide which array to display: "alternatives" or "similar"
  const alternativeCodes = code.alternatives
    ? code.alternatives
    : isUnspecifiedAnemia(code.name) && code.similar
    ? code.similar
    : [];

  const handleClose = () => {
    setShowAlternativeCodes(false);
  };

  const onChange = (option) => {
    setSelectedOption(option);
  };

  const handleReplace = async () => {
    if (!selectedOption) {
      return; // or show an error, e.g., alert("Please select an alternative code.")
    }

    // 1) Create updated array of codes by replacing the original code with the selectedOption
    //    We'll preserve the original codeType, but replace everything else with the new code data.
    const updatedCodes = selectedCodes.codes.map((c) => {
      if (c.code === code.code) {
        // Replace with chosen alternative code, but keep the same codeType
        return { ...selectedOption, codeType: c.codeType };
      }
      return c;
    });

    // 2) Update the parent's selectedCodes state
    setSelectedCodes({ codes: updatedCodes });

    // // 3) (Optional) Immediately save to DB here or only upon "Save Changes"
    // //    If you want to PATCH the server right away:
    // try {
    //   const response = await fetch(
    //     `${process.env.REACT_APP_API_URL}/notes/todos/icd10/${patientID}`,
    //     {
    //       method: "PATCH",
    //       headers: {
    //         "Content-Type": "application/json",
    //         token: localStorage.getItem("token"),
    //       },
    //       body: JSON.stringify({ codes: updatedCodes }),
    //     }
    //   );
    //   if (!response.ok) {
    //     throw new Error(`Error saving selected codes: ${response.statusText}`);
    //   }
    //   const data = await response.json();
    //   console.log("Successfully saved:", data);
    // } catch (error) {
    //   console.error("Failed to save alternative code:", error);
    // }

    // 4) Trigger any parent re-renders if needed
    setCodesChange(true);

    // 5) Close the modal
    handleClose();
  };

  return (
    <div className="fixed bg-gray-800 bg-opacity-80 inset-0 flex items-center justify-center z-50">
      <div className="relative flex flex-col items-center justify-center bg-white p-10 rounded-lg shadow-lg">
        <button
          onClick={handleClose}
          className="text-2xl absolute top-3 right-5 font-extrabold hover:text-accent1 text-light-grey"
        >
          X
        </button>
        <svg
          className="w-[80px] h-[80px] text-accent1 dark:text-white"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M8 20V7m0 13-4-4m4 4 4-4m4-12v13m0-13 4 4m-4-4-4 4"
          />
        </svg>

        <h2 className="flex flex-col text-3xl text-accent1 font-black mt-2 mb-1">
          <span className="text-center">Replace Code</span>
        </h2>
        <p className="flex flex-col text-light-grey text-left">
          <span>
            Please select one of the alternative codes below to replace the
            unspecified code.
          </span>
        </p>
        <h3 className="flex flex-col text-ktx-red font-black mb-1 mt-2 text-left">
          <span>{`${code.code} ${code.name}, `}</span>
        </h3>

        <div className="mt-2 mb-10">
          {alternativeCodes && alternativeCodes.length > 0 ? (
            alternativeCodes.map((option, index) => (
              <label
                className="flex flex-row text-accent1 items-center gap-2"
                key={index}
              >
                <input
                  className=" text-accent1 cursor-pointer"
                  type="radio"
                  name="codes"
                  // Check by code or the entire object
                  checked={selectedOption?.code === option.code}
                  onChange={() => onChange(option)}
                />
                {option.code}, {option.name}
              </label>
            ))
          ) : (
            <div className="text-gray-500 text-sm font-semibold text-center">
              There are no alternative codes associated with this code.
            </div>
          )}
        </div>

        <button
          onClick={handleReplace}
          className="bg-accent2 hover:bg-purple2 text-white py-2 px-4 rounded"
          style={{ width: "30vw" }}
        >
          Replace
        </button>
      </div>
    </div>
  );
}
