import React, { useState } from "react";

export default function CodeSuggestionsModal({
  setShowSuggestions,
  selectedCode,
  selectedCodes,
  setSelectedCodes,
  setCodesChange,
}) {
  // The "similar" array from the selectedCode
  const suggestionsList = selectedCode?.similar || [];

  // Manage checkbox states for each suggestion
  const [checkedStates, setCheckedStates] = useState(
    new Array(suggestionsList.length).fill(false)
  );

  const handleClose = () => {
    setShowSuggestions(false);
  };

  // Toggle checkbox state
  const handleCheckboxClick = (index) => {
    const updatedCheckedStates = [...checkedStates];
    updatedCheckedStates[index] = !updatedCheckedStates[index];
    setCheckedStates(updatedCheckedStates);
  };

  // Handle adding selected codes
  const handleAdd = () => {
    // 1) Filter to get selected suggestions
    const selectedItems = suggestionsList.filter(
      (_, idx) => checkedStates[idx]
    );

    if (selectedItems.length === 0) {
      // No suggestions selected, just close (or alert)
      handleClose();
      return;
    }

    // 2) Assign the parent codeType to each selected suggestion
    const updatedItemsWithType = selectedItems.map((item) => ({
      ...item,
      codeType: selectedCode.codeType, // ensures they inherit the parent's codeType
    }));

    // 3) Merge these updated items into the existing selectedCodes array
    const merged = [...selectedCodes.codes, ...updatedItemsWithType];

    // 4) DEDUPLICATE by `code` so we don’t add the same code multiple times
    const uniqueCodes = merged.filter((codeItem, index, self) => {
      return index === self.findIndex((t) => t.code === codeItem.code);
    });

    const updatedCodes = { ...selectedCodes, codes: uniqueCodes };

    // 5) Update the parent's state
    setSelectedCodes(updatedCodes);
    setCodesChange(true);

    // 6) Close the modal (no immediate DB save if you only do that on "Save Changes" or "Upload")
    handleClose();
  };

  return (
    <div className="fixed bg-gray-800 bg-opacity-80 inset-0 flex items-center justify-center z-50">
      <div className="relative flex flex-col items-center justify-center bg-white p-16 rounded-lg shadow-lg">
        <button
          onClick={handleClose}
          className="text-lg absolute top-3 right-5 font-extrabold text-accent1"
        >
          X
        </button>

        <div className="flex flex-col items-center justify-center">
          <h1 className="text-4xl text-accent1 font-black mt-4">
            Additional Code Suggestions
          </h1>
          <h3 className="text-sm text-light-grey font-black mt-2">
            These are additional codes typically added with this code.
          </h3>
          <h3 className="text-sm text-light-grey font-black">
            Please select any you'd like to add to the list.
          </h3>

          {selectedCode && (
            <div className="text-md text-ktx-red font-black mb-4 mt-2">
              {selectedCode.code} : {selectedCode.name}
            </div>
          )}
        </div>

        <div className="my-4">
          {suggestionsList.length > 0 ? (
            suggestionsList.map((item, index) => {
              // Check if this code is already in the parent’s selected codes
              const alreadyInSelected = selectedCodes.codes.some(
                (c) => c.code === item.code
              );

              return (
                <div key={index} className="flex items-center mb-2">
                  <input
                    type="checkbox"
                    disabled={alreadyInSelected}
                    checked={checkedStates[index] && !alreadyInSelected}
                    onChange={() => handleCheckboxClick(index)}
                    className={`mr-2 hover:cursor-pointer rounded-sm appearance-none w-5 h-5 border-2 
                      ${
                        alreadyInSelected
                          ? "border-gray-400 cursor-not-allowed"
                          : "border-accent1 checked:bg-accent1 checked:border-transparent"
                      }`}
                  />
                  <label
                    className={`text-accent1 ${
                      alreadyInSelected ? "opacity-50" : ""
                    }`}
                  >
                    {item.code}: {item.name}
                    {alreadyInSelected && " (already added)"}
                  </label>
                </div>
              );
            })
          ) : (
            <div className="text-gray-500 text-sm font-semibold text-center">
              There are no typically selected codes associated with this code.
            </div>
          )}
        </div>

        {suggestionsList.length > 0 && (
          <div className="flex mt-3">
            <button
              className="w-button-w bg-accent2 hover:bg-purple2 text-white py-2 px-4 rounded mr-2"
              onClick={handleAdd}
            >
              Add Selected
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
