import React, { useState, useEffect } from "react";
import EditPatient from "./EditPatient";
import "react-tooltip/dist/react-tooltip.css";
import { Link } from "react-router-dom";
import SearchBar from "./SearchBar";
import BulkDeletePatients from "./BulkDeletePatients/BulkDeletePatients";

//Table of patients/notes.
const ListNotes = ({
  isLoading,
  allPatients,
  setPatientsChange,
  patientsChange,
  name,
  turnaroundTime,
  org,
  // pass in api fetch from AllNotes
  getName,
  setIsEditing,
  isEditing,
  //array for unit testing purposes
  pickedDate,
}) => {
  // state variable for search bar functionality.
  const [searchQuery, setSearchQuery] = useState("");
  const [filterOption, setFilterOption] = useState("All");
  const [selectedPatients, setSelectedPatients] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const userId = allPatients[0]?.user_id;
  const pathName = window.location.pathname;

  /*/ Sorting Patients by Date of Appointment Functionality /*/
  const sortedByDateOfAppt = (patients) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Sort the patients array based on date_of_appt
    pathName === "/dashboard"
      ? patients.sort((a, b) => {
          const dateA = new Date(a.created_at);
          const dateB = new Date(b.created_at);
          return dateA - dateB;
        })
      : patients.sort((a, b) => {
          const dateA = new Date(a.date_of_appt);
          const dateB = new Date(b.date_of_appt);
          return dateB - dateA;
        });

    // Group patients by month and day
    const groupedByDate = patients.reduce((acc, patient) => {
      const date = new Date(patient.date_of_appt);
      const month = months[date.getMonth()];
      const day = date.getDate();
      const year = date.getFullYear();

      const key = `${month} ${day}, ${year}`;

      // Find existing group or create a new one
      const existingGroup = acc.find((item) => item.date === key);
      if (existingGroup) {
        existingGroup.patients.push(patient);
      } else {
        acc.push({
          date: key,
          patients: [patient],
          monthYear: `${month} ${year}`,
        });
      }
      return acc;
    }, []);
    return groupedByDate;
  };
  const sortedPatientsByMonthDay = sortedByDateOfAppt(allPatients);

  useEffect(() => {
    sortedByDateOfAppt(allPatients);
  }, [filterOption, pickedDate]);

  useEffect(() => {
    // conditional so setInterval only occurs when isEditing is false
    let intervalId;

    if (!isEditing) {
      intervalId = setInterval(getName, 5000);
    }
    return () => clearInterval(intervalId);
  }, [patientsChange, isEditing]);

  const handleFilterChange = (event) => {
    setFilterOption(event.target.value);
  };

  /* sortedPatientsByMonthDay EXAMPLE:
      [
        {date: 'July 16, 2024', monthYear: 'July 2024' patients: [
          {mrn: '54321', description: 'Travis', status: 'Review'}]}
        {date: 'July 19, 2024', monthYear: 'July 2024' patients: [
          {mrn: '54321', description: 'Test Patient', status: 'Review'},
          {mrn: '54321', description: 'Braxton', status: 'Review'},
          {mrn: '54321', description: 'Brian', status: 'Review'},
          {mrn: '54321', description: 'Caroline', status: 'Review'},
          {mrn: '54321', description: 'Andrea', status: 'Review'}]}
      ]
    */

  /*/ filter patients based on search input value and filter option selected in dropdown /*/
  const filteredPatients = sortedPatientsByMonthDay.flatMap((appointment) => {
    /* example appointment
    {date: 'July 19, 2024', monthYear: 'July 2024' patients: [
      {mrn: '54321', description: 'Test Patient', status: 'Review'},
      {mrn: '54321', description: 'Braxton', status: 'Review'}]}
    */
    const isDateMatched =
      appointment.date
        ?.match(/(\b\d+\b)|(\b\d{2}\d{2}\d{4}\b)|(\b[A-Z][a-z]+\b)/g)
        .some((a) => a?.toLowerCase().includes(searchQuery?.toLowerCase())) ||
      appointment.date?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      appointment.date
        ?.toLowerCase()
        .split(",")
        .flatMap((word) => word.split(","))
        .join("")
        .includes(searchQuery.toLowerCase());

    const isMonthYearMatched = appointment.monthYear
      ?.toLowerCase()
      .includes(searchQuery.toLowerCase());

    const matchedPatientsDescrMrn = appointment.patients.filter((patient) => {
      return (
        (patient.mrn?.includes(searchQuery.toLowerCase()) ||
          patient.description
            ?.toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          patient.description
            ?.toLowerCase()
            .match(/(\w+)/g)
            ?.some((word) => word.includes(searchQuery.toLowerCase()))) &&
        (patient.status === filterOption || filterOption === "All")
      );
    });

    const isStatusMatched = appointment.patients.filter((patient) => {
      return patient.status === filterOption || filterOption === "All";
    });

    if ((isMonthYearMatched || isDateMatched) && isStatusMatched.length > 0) {
      const clonedAppointment = {
        ...appointment,
        patients:
          isStatusMatched.length > 0 ? isStatusMatched : appointment.patients,
      };
      return [clonedAppointment];
    } else if (matchedPatientsDescrMrn.length > 0) {
      const clonedAppointment = {
        ...appointment,
        patients:
          matchedPatientsDescrMrn.length > 0
            ? matchedPatientsDescrMrn
            : appointment.patients,
      };
      return [clonedAppointment];
    } else {
      return [];
    }
  });

  // conditional to generate bg color based on the note status.
  const getStatusClassName = (status) => {
    switch (status) {
      case "Ready to Record":
        return "bg-ktx-green";
      case "Review":
        return "bg-accent2";
      case "Processing Audio":
        return "bg-light-grey";
      case "Locked":
        return "bg-dark-purple";
      case "Uploading Audio":
        return "bg-light-grey";
      default:
        return "bg-light-grey";
    }
  };

  // TODO: Refactor this to follow best practices
  // Function to generate link to /record page for locked status.
  const generateRecordLink = (patient) => {
    return (
      <button
        type="button"
        data-bs-dismiss="modal"
        disabled={patient.status === "Locked"}
        className={`hover:cursor-pointer py-2 px-8 rounded-md text-background1 ${
          patient.status === "Locked"
            ? "bg-transparent-grey text-background1 cursor-not-allowed"
            : "text-background1 bg-ktx-green"
        }`}
      >
        <Link
          to="/record"
          state={{
            patientID: patient.patient_id,
            patientName: patient.description,
            doctorName: name,
            MRN: patient.mrn,
            turnaroundTime: turnaroundTime,
          }}
          className="px-4 py-2 rounded-md mr-3 text-md"
        >
          Add Another Recording
        </Link>
      </button>
    );
  };

  /*/ Beginning of PAGINATION Functionality /*/
  const [currentPage, setCurrentPage] = useState(1);
  const totalNotes = filteredPatients.reduce(
    (acc, entry) => acc + entry.patients.length,
    0
  );
  const totalPages = Math.ceil(totalNotes / 25);

  const getPatientsForPage = () => {
    const startIndex = (currentPage - 1) * 25;
    const endIndex = startIndex + 25;

    let accumulatedPatients = [];
    let accumulatedCount = 0;

    // Iterate through filteredPatients to collect patients for the current page
    for (let i = 0; i < filteredPatients.length; i++) {
      const { date, patients } = filteredPatients[i];
      const patientsCount = patients.length;

      // Iterate through patients for the current date
      for (let j = 0; j < patientsCount; j++) {
        if (accumulatedCount >= startIndex && accumulatedCount < endIndex) {
          // Add patient to the accumulated list for the current page
          if (!accumulatedPatients.some((item) => item.date === date)) {
            accumulatedPatients.push({ date, patients: [] });
          }
          accumulatedPatients
            .find((item) => item.date === date)
            .patients.push(patients[j]);
        }
        accumulatedCount++;

        if (accumulatedCount >= endIndex) {
          // We've collected enough patients for the current page
          break;
        }
      }
      if (accumulatedCount >= endIndex) {
        // We've collected enough patients for the current page
        break;
      }
    }
    return accumulatedPatients;
  };

  const goToPage = (page) => {
    setCurrentPage(page);
  };

  let notesToShow = filteredPatients;
  pathName === "/notes"
    ? (notesToShow = getPatientsForPage())
    : (notesToShow = filteredPatients);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [totalPages]);
  /*/ End of PAGINATION Functionality /*/

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedPatients([]);
    } else {
      const allPatientIds = notesToShow.flatMap((note) =>
        note.patients.map((patient) => patient.patient_id)
      );
      setSelectedPatients(allPatientIds);
    }
    setSelectAll(true);
  };

  const handleSelectPatient = (patientId) => {
    if (selectedPatients.includes(patientId)) {
      setSelectedPatients(selectedPatients.filter((id) => id !== patientId));
    } else {
      setSelectedPatients([...selectedPatients, patientId]);
    }
  };

  const countFilteredPatients = () => {
    let count = 0;
    for (let date of filteredPatients) {
      for (let patient of date.patients) {
        count++;
      }
    }
    return count;
  };

  const filteredPatientsCounter = countFilteredPatients();

  const fullySelectedPatientArray = () => {
    if (
      (selectedPatients?.length === filteredPatientsCounter ||
        selectedPatients?.length === 25) &&
      filteredPatientsCounter > 0
    )
      return setSelectAll(true);
    else if (
      selectedPatients?.length < filteredPatientsCounter &&
      filteredPatientsCounter > 0
    )
      return setSelectAll(false);
  };

  useEffect(() => {
    fullySelectedPatientArray();
  }, [selectedPatients, filteredPatients]);

  useEffect(() => {
    setSelectedPatients([]);
  }, [pickedDate, searchQuery, filterOption]);

  return (
    <div className="flex flex-col">
      <div className="flex items-baseline w-list justify-between">
        <div className="flex items-baseline">
          <SearchBar
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
          />
          {totalPages > 1 && pathName === "/notes" && (
            <div className="text-accent1 flex mt-2 ml-3">
              <div className="flex h-[18px] min-w-[32px]">
                {currentPage !== 1 && (
                  <button
                    className="mr-2"
                    disabled={currentPage === 1}
                    onClick={() => goToPage(currentPage - 1)}
                  >
                    <svg
                      className="text-transparent-grey hover:text-light-grey w-6 h-6 text-gray-800 dark:text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m15 19-7-7 7-7"
                      />
                    </svg>
                  </button>
                )}
              </div>
              <span className="">
                Page {currentPage} of {totalPages}
              </span>
              <div className="flex min-w-[32px]">
                {currentPage !== totalPages && (
                  <button
                    className="ml-2"
                    disabled={currentPage === totalPages}
                    onClick={() => goToPage(currentPage + 1)}
                  >
                    <svg
                      className="text-transparent-grey hover:text-light-grey w-6 h-6 text-gray-800 dark:text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m9 5 7 7-7 7"
                      />
                    </svg>
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="flex relative items-baseline">
          <label htmlFor="filter" className="mr-2 font-semibold">
            Filter by Status
          </label>
          <select
            id="filter"
            value={filterOption}
            onChange={handleFilterChange}
            className="px-3 py-1 border rounded-md text-knowtex-grey"
            style={{
              maxWidth: "100px",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            <option value="All">All</option>
            <option value="Ready to Record">Ready to Record</option>
            <option value="Review">Review</option>
            <option value="Processing Audio">Processing Audio</option>
            {org === "4DEMR" && <option value="Locked">Locked</option>}
          </select>
          {selectedPatients.length !== 0 &&
          notesToShow.length !== 0 &&
          pathName === "/dashboard" &&
          allPatients[0]?.description !== null ? (
            <BulkDeletePatients
              setSelectAll={setSelectAll}
              userId={userId}
              setSelectedPatients={setSelectedPatients}
              selectedPatients={selectedPatients}
              setPatientsChange={setPatientsChange}
              pickedDate={pickedDate}
              allPatients={allPatients}
            />
          ) : null}
        </div>
      </div>
      {allPatients[0]?.description !== null && pathName === "/dashboard" ? (
        <div className="flex mt-4 w-full">
          <h2 className="text-lg text-accent1">
            Total Patients for the Day: {allPatients?.length}
          </h2>
          {filteredPatients.length !== 0 &&
          pathName === "/dashboard" &&
          allPatients[0]?.description !== null ? (
            <div className="flex flex-1 justify-end">
              <button
                className="text-transparent-grey hover:text-light-grey"
                onClick={handleSelectAll}
              >
                {selectAll ? "Deselect All" : "Select All"}
              </button>
            </div>
          ) : null}
        </div>
      ) : null}
      {filteredPatients.length === 0 && allPatients[0]?.description !== null ? (
        <div className="flex items-center w-list justify-center mt-4">
          <h2 className="text-lg text-light-grey">No Results</h2>
        </div>
      ) : null}
      {allPatients[0]?.description === null && pathName === "/dashboard" ? (
        <div className="flex items-center w-list justify-center mt-4">
          <h2 className="text-lg text-light-grey">
            There are no patients registered for this date.
          </h2>
        </div>
      ) : null}
      {notesToShow[0]?.patients[0]?.patient_id !== null &&
        notesToShow.map((sortedPatients, i) => (
          <div className="container m-0" key={i}>
            {pathName === "/notes" ? (
              <div
                key={i}
                className="text-accent1 mt-4 border-b-2 text-xl border-[#E5E7EB]"
              >
                {sortedPatients.date}
              </div>
            ) : null}
            {sortedPatients.patients &&
              sortedPatients.patients.length !== 0 &&
              sortedPatients.patients[0].patient_id !== null &&
              sortedPatients.patients.map((patient, index) => (
                <div
                  key={index}
                  // to={getToPath(patient)}
                  className="note flex w-list"
                >
                  <div
                    key={index}
                    className={`flex items-center w-list justify-between mb-2 mt-2 text-background1 border rounded-2xl shadow-drop-shadow ${getStatusClassName(
                      patient.status
                    )} p-3`}
                  >
                    <div>
                      <h1 className="text-xl mobile:text-lg ">
                        {patient.description}
                      </h1>
                      <h1 className="text-md font-thin mobile:text-sm">
                        {" "}
                        {patient.mrn}
                      </h1>
                      <h1 className="text-md font-thin mobile:text-sm">
                        {" "}
                        {patient.surgery === true ? "Surgery" : "Visit"}
                      </h1>
                    </div>
                    {patient.status === "Review" ? (
                      <EditPatient
                        patient={patient}
                        setPatientsChange={setPatientsChange}
                        org={org}
                        setIsEditing={setIsEditing}
                        isEditing={isEditing}
                        name={name}
                        getName={getName}
                        generateRecordLink={generateRecordLink}
                        status={patient.status}
                      />
                    ) : patient.status === "Ready to Record" ? (
                      <div className="flex items-center">
                        <div className="pr-1">
                          <svg
                            className="w-6 h-6 text-background1"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M19 9v3a5 5 0 0 1-5 5h-4a5 5 0 0 1-5-5V9m7 9v3m-3 0h6M11 3h2a3 3 0 0 1 3 3v5a3 3 0 0 1-3 3h-2a3 3 0 0 1-3-3V6a3 3 0 0 1 3-3Z"
                            />
                          </svg>
                        </div>
                        <Link
                          data-testid="record-link"
                          to="/record"
                          state={{
                            patientID: patient.patient_id,
                            patientName: patient.description,
                            doctorName: name,
                            MRN: patient.mrn,
                            turnaroundTime: turnaroundTime,
                          }}
                          className="hover:text-secondary-text-grey text-background1 text-xl mobile:text-lg"
                        >
                          Ready to Record
                        </Link>
                      </div>
                    ) : patient.status === "Processing Audio" ? (
                      <Link
                        to="/record"
                        state={{
                          patientID: patient.patient_id,
                          patientName: patient.description,
                          doctorName: name,
                          MRN: patient.mrn,
                          turnaroundTime: turnaroundTime,
                        }}
                        className="hover:text-secondary-text-grey text-background1 text-xl"
                      >
                        {patient.status}
                      </Link>
                    ) : patient.status === "Locked" ? (
                      // <div
                      //   className="flex items-center text-ktx-red"
                      //   data-tooltip-content="Locked notes can be viewed in the EMR."
                      //   data-tooltip-id="tooltip-id"
                      // >
                      //   <div className="pr-1 text-ktx-red">
                      //     <svg
                      //       className="w-6 h-6  text-ktx-red hover:text-secondary-text-grey"
                      //       aria-hidden="true"
                      //       xmlns="http://www.w3.org/2000/svg"
                      //       fill="none"
                      //       viewBox="0 0 24 24"
                      //     >
                      //       <path
                      //         stroke="currentColor"
                      //         stroke-linecap="round"
                      //         stroke-linejoin="round"
                      //         stroke-width="2"
                      //         d="M12 14v3m-3-6V7a3 3 0 1 1 6 0v4m-8 0h10c.6 0 1 .4 1 1v7c0 .6-.4 1-1 1H7a1 1 0 0 1-1-1v-7c0-.6.4-1 1-1Z"
                      //       />
                      //     </svg>
                      //   </div>
                      //   Locked
                      //   <ReactTooltip id="tooltip-id" />

                      // </div>
                      <EditPatient
                        patient={patient}
                        setPatientsChange={setPatientsChange}
                        org={org}
                        setIsEditing={setIsEditing}
                        isEditing={isEditing}
                        getName={getName}
                        generateRecordLink={generateRecordLink}
                        status={patient.status}
                      />
                    ) : (
                      <div
                        className={`hover:text-secondary-text-grey text-background1 text-xl ${getStatusClassName(
                          patient.status
                        )}`}
                      >
                        {patient.status}
                      </div>
                    )}
                  </div>
                  <div className="flex items-center ml-4">
                    {/* <DeletePatient
                      key={`delete-${patient.patient_id}`}
                      patient={patient}
                      setPatientsChange={setPatientsChange}
                    /> */}
                    {pathName === "/dashboard" ? (
                      <input
                        className="rounded-sm border-accent1 hover:cursor-pointer"
                        type="checkbox"
                        checked={selectedPatients.includes(patient.patient_id)}
                        onChange={() => handleSelectPatient(patient.patient_id)}
                      />
                    ) : null}
                  </div>
                </div>
              ))}
          </div>
        ))}
      {totalPages > 1 && pathName === "/notes" && (
        <div className="text-accent1 flex justify-end mt-2 ml-3">
          <div className="flex h-[18px] min-w-[32px]">
            {currentPage !== 1 && (
              <button
                className="mr-2"
                disabled={currentPage === 1}
                onClick={() => goToPage(currentPage - 1)}
              >
                <svg
                  className="text-transparent-grey hover:text-light-grey w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m15 19-7-7 7-7"
                  />
                </svg>
              </button>
            )}
          </div>
          <span className="">
            Page {currentPage} of {totalPages}
          </span>
          <div className="flex min-w-[32px]">
            {currentPage !== totalPages && (
              <button
                className="ml-2"
                disabled={currentPage === totalPages}
                onClick={() => goToPage(currentPage + 1)}
              >
                <svg
                  className="text-transparent-grey hover:text-light-grey w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m9 5 7 7-7 7"
                  />
                </svg>
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ListNotes;
