import React, { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import {
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

export default function CheckoutForm({ setIsSubscribed }) {
  //grabbing userId from jwt
  const decoded_token = jwt_decode(localStorage.token);
  const userId = decoded_token.user.id;

  const stripe = useStripe();
  const elements = useElements();

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const [fullName, setFullName] = useState("");

  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [email, setEmail] = useState("");

  const [err, setErr] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);

  const [price, setPrice] = useState(150);
  const [priceId, setPriceId] = useState(process.env.REACT_APP_STRIPE_MONTHLY_PLAN);

  const [discountCode, setDiscountCode] = useState("");
  const [discountApplied, setDiscountApplied] = useState(false);
  const [discountAmount, setDiscountAmount] = useState(0);

  // to grab email and name when they load the page initially
  useEffect(() => {
    (async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/dashboard/userinfo`,
          {
            method: "GET",
            headers: { token: localStorage.token },
          }
        );

        const parseRes = await response.json();
        setFullName(parseRes[0].user_name);
        setEmail(parseRes[0].user_email);
      } catch (err) {
        console.error("Error fetching user info:", err.message);
      }
    })()
  }, [])

  // helper to auto-fill the name with current userinfo
  useEffect(() => {
    const splitName = fullName.split(" ");

    setFName(splitName[0]);
    setLName(splitName[1]);
  }, [fullName])

  useEffect(() => {
    // async iife to fetch price based on priceId
    (async () => {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/stripe-price/${priceId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          "token": localStorage.token
        },
      });

      const price = await response.json();

      setPrice(price);
    })()

    setDiscountApplied(false);
  }, [priceId])

  //display price based on env value

  const handleStripeSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        email: email,
        name: `${fName} ${lName}`
      },
    });

    if (error) {
      setIsLoading(false);
      return;
    }
    const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/stripe-subscribe`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "token": localStorage.token
      },
      body: JSON.stringify({ paymentMethod, name: `${fName} ${lName}`, email, userId, priceId, discountCode }),
    });

    const subscription = await response.json();

    if (subscription.error) {
      toast.error(subscription.error.message);
      toast.error("If you feel this is an error, please contact support@knowtex.ai");
    } else {
      setIsSubscribed(true);
      toast.success("Subscription Successful")
      navigate("/login");
      localStorage.removeItem("subscription_ends");
    }

    setIsLoading(false);
  }

  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setIsDisabled(event.empty);
    setErr(event.error ? event.error.message : "");
  };

  useEffect(() => {
    if (err) {
      toast.error(err);
    }
  }, [err])

  const handleOnCouponSubmit = async (e) => {
    e.preventDefault();
    const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/stripe-coupon`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "token": localStorage.token
      },
      body: JSON.stringify({ discountCode, price }),
    });

    const coupon = await response.json();

    if (coupon.error) {
      toast.error(coupon.error.message);
    } else {
      setDiscountApplied(true);
      setDiscountAmount(coupon.discountAmount)
      setPrice(coupon.price);
    }
  }

  return (
    <div className="flex flex-col">
      <div className="gap-4 flex justify-center items-center">
        <select className="w-[50%] mx-auto border-none shadow-knowtex-shadow rounded-md text-accent1" required value={priceId} onChange={(e) => setPriceId(e.target.value)}>
          <option value={process.env.REACT_APP_STRIPE_ANNUAL_PLAN}>Annual</option>
          <option value={process.env.REACT_APP_STRIPE_MONTHLY_PLAN}>Monthly</option>
        </select>
        <>
          {discountApplied !== true ? (<form className="flex gap-4" onSubmit={handleOnCouponSubmit}>
            <input className="w-[50%] my-[1rem] border-none shadow-knowtex-shadow rounded-md text-accent1" type="text" placeholder="Coupon" onChange={(e) => setDiscountCode(e.target.value)} value={discountCode} />
            <button className="w-[50%] my-[1rem] border-solid border-ktx-purple border-[1px] hover:bg-ktx-purple hover:text-white rounded-md text-ktx-purple" type="submit" disabled={discountApplied === true || discountCode === ""}>Apply</button>
          </form>) : (
            <div className="flex gap-4">
              <p className="w-full my-[1rem] text-ktx-green">Coupon Applied -${discountAmount.toFixed(2)}</p>
            </div>
          )}
        </>
      </div>
      <form id="payment-form" onSubmit={handleStripeSubmit}>
        <h5 className="text-accent2 font-semibold text-[1.25rem] tracking-[.05rem]">Subscribe to Knowtex</h5>
        <div className="flex text-accent1 gap-1 mb-[1.5rem]">
          <h3 className="text-[2rem] font-bold">${price.toFixed(2)}</h3>
          <div className="flex flex-col">
            {priceId === process.env.REACT_APP_STRIPE_MONTHLY_PLAN ?
              <>
                <p className="tracking-[0.08rem]">{discountApplied ? `per month, for the first month` : "per month"}</p>
                {discountApplied && <p className="text-accent1 text-[.75rem]">Renews at ${(price + discountAmount).toFixed(2)} per month</p>}
              </>
              :
              <>
                <p className="tracking-[0.08rem]">{discountApplied ? `per year, for the first year` : "per year"}</p>
                {discountApplied && <p className="text-accent1 text-[.75rem]">Renews at ${(price + discountAmount).toFixed(2)} per month</p>}
              </>
            }
          </div>
        </div>
        <input type="text" className="w-[100%] my-[1rem] border-none shadow-knowtex-shadow rounded-md text-accent1" required value={fName} placeholder="First Name" onChange={(e) => setFName(e.target.value)} />
        <input type="text" className="w-[100%] my-[1rem] border-none shadow-knowtex-shadow rounded-md text-accent1" required value={lName} placeholder="Last Name" onChange={(e) => setLName(e.target.value)} />
        <input type="email" className="w-[100%] my-[1rem] border-none shadow-knowtex-shadow rounded-md text-accent1" required id="email" value={email} placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
        <CardElement className="px-[.5rem] py-[.75rem] w-[100%] my-[1rem] border-none shadow-knowtex-shadow rounded-md text-accent1" id="payment-element" onChange={handleChange} />
        <button className="mt-[3rem]" disabled={isLoading || !stripe || !elements || isDisabled} id="submit">
          <span id="button-text" className="tracking-[.1rem]">
            {isLoading ? <div className="spinner" id="spinner"></div> : "Subscribe"}
          </span>
        </button>
        <p className="mx-auto mt-[1.5rem] text-accent1 text-center w-[60%]"><button className="hover:text-accent1 text-accent2 font-semibold" onClick={() => window.Atlas.chat.openWindow()} >Questions or concerns?</button> Please reach us at <a className="hover:text-accent1 text-accent2 font-semibold" href="mailto:support@knowtex.ai">support@knowtex.ai</a> or call <a className="hover:text-accent1 text-accent2 font-semibold" href="tel:+1-323-679-6957">+1 (323) 679-6957</a></p>
      </form>
    </div>
  );
}